import React, { useEffect } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import styled from "styled-components"

const EmblaWrapper = styled.div`
  .is-draggable {
    height: 500px;
  }

  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    align-content: flex-start;
  }
  .embla__slide {
    position: relative;
    min-width: 100%;
  }
`

const EmblaCarousel = props => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    draggable: false,
  })

  useEffect(() => {
    if (embla) {
      // Embla API is ready
    }
  }, [embla])

  function emblaScroll() {
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }

  return (
    <EmblaWrapper>
      <EmblaCarouselReact>
        <div onClick={() => emblaScroll()} className="embla__container">
          {props.children}
        </div>
      </EmblaCarouselReact>
    </EmblaWrapper>
  )
}

export default EmblaCarousel
