import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmblaCarousel from "../components/carousel"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "photography" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  return (
    <Layout>
      <SEO title="Home" />
      <p style={{ textAlign: "center" }}>click to scroll</p>
      <EmblaCarousel>
        {images.map(image => {
          return (
            <div className="embla__slide">
              <Img
                fluid={image.node.childImageSharp.fluid}
                key={image.node.childImageSharp.fluid.src}
                imgStyle={{ height: "500px", objectFit: "contain" }}
              />
            </div>
            // <img src={image.absolutePath} alt="" />
          )
        })}
      </EmblaCarousel>
    </Layout>
  )
}

export default IndexPage
